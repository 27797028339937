/**
 * @module Events
 * @description Some event functions for use in other modules
 */
import assign from 'lodash-es/assign';

/**
 * @function ready
 * @description
 */
export const ready = ( fn ) => {
  if ( document.readyState !== 'loading' ) {
    fn();
  } else if ( document.addEventListener ) {
    document.addEventListener( 'DOMContentLoaded', fn );
  } else {
    document.attachEvent( 'onreadystatechange', () => {
      if ( document.readyState !== 'loading' ) {
        fn();
      }
    } );
  }
};

/**
 * @function trigger
 * @description
 */
export const trigger = ( opts ) => {
  let event;
  const options = assign( {
    data: {},
    el: document,
    event: '',
    native: true
  }, opts );

  if ( options.native ) {
    event = document.createEvent( 'HTMLEvents' );
    event.initEvent( options.event, true, false );
  } else {
    try {
      event = new CustomEvent( options.event, { detail: options.data } );
    } catch ( e ) {
      if ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ) {
        console.error( 'JSON parsing failed:', e );
      }
      event = document.createEvent( 'CustomEvent' );
      event.initCustomEvent( options.event, true, true, options.data );
    }
  }

  options.el.dispatchEvent( event );
};
